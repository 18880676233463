export default {
  
    name:'sperikonIndex',
    path:'/',
    redirect:'/sperikon',
    component:()=> import('@/layouts/sperikonProduct.vue'),
    children: [
     {
       name:'sperikon',
       path:'sperikon',
       component:()=> import('@/views/sperikonProduct/index.vue')
     },
   ]
   
 }