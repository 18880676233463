export default {
  path: "/warehouse",
  name: "warehouse",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/warehouse/inStock",
  children: [
    {
      name: 'inStock',
      path: "inStock",
      meta: { title: "入库任务", permission: "inStock", keepAlive:false },
      component: () => import("@/views/warehouse/inStock/index"),
    },
    {
      path: "inStock_create",
      meta: { title: "入库创建", notMenu: true, permission: "inStock" },
      component: () => import("@/views/warehouse/inStockCreate/index"),
    },
    {
      name: 'hand_inStock_create',
      path: "hand_inStock_create",
      meta: { title: "入库创建", notMenu: true, permission: "inStock" },
      component: () => import("@/views/warehouse/inStockCreate/inStockCreate"),
    },
    {
      path: "inStock_detail",
      meta: {
        title: "入库通知单详情",
        notMenu: true,
        permission: "inStock",
      },
      component: () => import("@/views/warehouse/inStockDetail/index"),
    },
    {
      path: "inStock_record_detail",
      meta: {
        title: "入库记录详情",
        notMenu: true,
        permission: "inStock",
      },
      component: () => import("@/views/warehouse/inStockRecordDetail/index"),
    },
    {
      name: 'outStock',
      path: "outStock",
      meta: { title: "出库任务", permission: "outStock", keepAlive:false },
      component: () => import("@/views/warehouse/outStock/index"),
    },
    {
      path: "outStock_create",
      meta: { title: "出库创建", notMenu: true, permission: "outStock" },
      component: () => import("@/views/warehouse/outStockCreate/index"),
    },
    {
      path: "hand_OutStock_create",
      meta: { title: "出库创建", notMenu: true, permission: "outStock" },
      component: () => import("@/views/warehouse/outStockCreate/outStockCreate"),
    },
    {
      path: "outStock_detail",
      meta: {
        title: "出库通知单详情",
        notMenu: true,
        permission: "outStock",
      },
      component: () => import("@/views/warehouse/outStockDetail/index"),
    },
    {
      path: "outStock_record_detail",
      meta: {
        title: "出库记录详情",
        notMenu: true,
        permission: "outStock",
      },
      component: () => import("@/views/warehouse/outStockRecordDetail/index"),
    },
    {
      name: 'allocation',
      path: "allocation",
      meta: { title: "调拨", permission: "allocation",keepAlive:false },
      component: () => import("@/views/warehouse/allocation/index"),
    },
    {
      name: 'allocation_create',
      path: "allocation_create",
      meta: {
        title: "调拨创建",
        notMenu: true,
        permission: "allocation",
      },
      component: () => import("@/views/warehouse/allocationCreate/index"),
    },
    {
      path: "allocationDetail_detail",
      meta: {
        title: "调拨单详情",
        notMenu: true,
        permission: "allocation",
      },
      component: () => import("@/views/warehouse/allocationDetail/index"),
    },
    {
      name: 'inventory',
      path: "inventory",
      meta: { title: "盘点", permission: "inventory", keepAlive:false},
      component: () => import("@/views/warehouse/inventory/index"),
    },
    {
      name: 'inventory_create',
      path: "inventory_create",
      meta: {
        title: "盘点创建",
        notMenu: true,
        permission: "inventory",
      },
      component: () => import("@/views/warehouse/inventoryCreate/index"),
    },
    {
      path: "inventory_detail",
      meta: {
        title: "盘点详情",
        notMenu: true,
        permission: "inventory",
      },
      component: () => import("@/views/warehouse/inventoryDetail/index"),
    },
    {
      name: 'flow',
      path: "flow",
      meta: { title: "库存流水", permission: "ware_house_flow", keepAlive:false },
      component: () => import("@/views/warehouse/flow/index"),
    },
    {
      path: "flow_detail",
      meta: { title: "库存流水详情", notMenu: true, permission: "ware_house_flow" },
      component: () => import("@/views/warehouse/flowDetail/index"),
    },
    {
      name: 'stock_report',
      path: 'stock_report',
      meta: { title: '库存报表', permission: 'stock_report', keepAlive:false},
      component: () => import('@/views/report/stockReport/index'),
    },
  ],
};
