import Cookies from "js-cookie";
import router from "@/router";
import axios from "axios";

const GET_TOKEN_URL = "/user/get_token/";
const REFRESH_TOKEN_URL = "/user/refresh_token/";
const LOGIN_PATH = "/user/login";
const Register_Team_URL = "/team/register/";
let requestQueue = [],
  isRefreshing = false;

const instance = axios.create({
  baseURL: "https://backend.sperikon.com/api/",
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});


// const instance = axios.create({
//   baseURL: "http://127.0.0.1:8000/api/",
//   timeout: 10000,
//   headers: { "Content-Type": "application/json" },
// });


instance.interceptors.request.use(
  (config) => {
    if (
      !config.url.includes(GET_TOKEN_URL) &&
      !config.url.includes(REFRESH_TOKEN_URL) &&
      !config.url.includes(Register_Team_URL)
    ) {
      config.headers.Authorization = "Bearer " + Cookies.get("access");
    }

    console.info("Send request:", config);
    localStorage.setItem('closeTime', Date.now());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {

    // console.log('错误', error.response);

    if (!error.response) return Promise.reject(error);
    console.error("Request error:", error.response);
    // console.error("Request error:", error.response.status);
    if(error.response.data.number){
      window.antd.message.warning(error.response.data.number);
    }
    if (error.response.data.detail&&!error.response.data.detail.includes('无效页面')) {
      window.antd.message.warning(error.response.data.detail)
      if(error.response.data.detail.includes('已到期, 到期日期:')) {

        //删除会话记录
        Cookies.remove('access');
        Cookies.remove('refresh');
        Cookies.remove('name');
        Cookies.remove('id');
        Cookies.remove('username');
        setTimeout(() => {
          window.location.href = '/user/sign-in'
        },1500)

      }

      if (error.response.data.detail == '抱歉，您已被强制下线') {
        //删除会话记录
        Cookies.remove('access');
        Cookies.remove('refresh');
        Cookies.remove('name');
        Cookies.remove('id');
        Cookies.remove('username');
        window.location.href = '/user/sign-in'
        setTimeout(() => {
          window.alert('抱歉，您已被强制下线！请重新登录或者询问管理员')
        },10)

      }
    }
    if (error.response.data.detail&&error.response.data.detail.includes('用户不存在')) {

      if(window.location.pathname!=='/user/sign-in') {
        setTimeout(() => {
          window.location.href = '/user/sign-in'
        },1500)
      }
   
    }
    if (error.response.status >= 500) {
      window.antd.message.error("服务器错误");
      return Promise.reject(error);
    }
    if (
      error.response.status == 401 &&
      !error.config.url.includes(REFRESH_TOKEN_URL)
    ) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          requestQueue.push(() => {
            resolve(instance(error.config));
          });
        });
      } else {
        isRefreshing = true;
        try {
          try {
            const data = await refreshToken();
            Cookies.set("access", data.access);
            requestQueue.forEach((fn) => fn());
            requestQueue = [];
            return await instance(error.config);
          } catch (error_1) {
            if (error_1.response.status == 401) {
              redirectLogin();
              window.antd.message.error("令牌过期, 请重新登录");
            }
            return await Promise.reject(error_1);
          }
        } finally {
          isRefreshing = false;
        }
      }
    }
    // Vue.prototype.$message.error(error.message);
    // window.antd.message.error(error.response.data.detail);
    return Promise.reject(error);
  }
);

function refreshToken() {
  return request({
    url: REFRESH_TOKEN_URL,
    method: "post",
    data: { refresh: Cookies.get("refresh") },
  });
}

function redirectLogin() {
  requestQueue = [];
  router.push(LOGIN_PATH);
}

export default function request(item) {
  let { data = {} } = item;
  for (let key in data) {
    if (data[key] == undefined) delete data[key];
  }

  return instance(item);
}

// 页面刷新后，主题样式消失，对于主题样式进行重载

function reloadThemeStyle() {

  // console.log((JSON.parse(localStorage.getItem('themeStyleConfigs'))) );
  if(JSON.parse(localStorage.getItem('themeStyleConfigs'))===null) {
    return
  }

  if(JSON.parse(localStorage.getItem('themeStyleConfigs'))===null) {
    return
  }
  //  console.log('主题重新加载');
   window.less.modifyVars(JSON.parse(localStorage.getItem('themeStyleConfigs')) )

}
reloadThemeStyle()




