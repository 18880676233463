export default {
  path: '/',
  name: 'pay',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'pay',
      name: 'pay',
      meta: { title: '增值服务', permission: 'pay' },
      component: () => import('@/views/pay/index.vue'),
    },
  ],
}